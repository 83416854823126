import * as BABYLON from '@babylonjs/core/Legacy/legacy';
import Workshop from './workshop';
import { Part } from './part';
import { InteractionTarget, InteractionState } from './interactionTarget';

export class Spawner extends InteractionTarget {
  public readonly rootMesh: BABYLON.Mesh;

  private readonly _material: BABYLON.PBRMetallicRoughnessMaterial;
  private readonly _defaultColor = BABYLON.Color3.Blue();
  private readonly _hoverColor = BABYLON.Color3.Red();

  constructor(name: string, private readonly _workshop: Workshop) {
    super();
    const cupShape = { height: 0.1, diameterBottom: 0.1, diameterTop: 0.14, subdivisions: 20 };

    const offsetMatrix = BABYLON.Matrix.Identity();
    offsetMatrix.setTranslation(new BABYLON.Vector3(0, cupShape.height / 2, 0));

    this.rootMesh = BABYLON.MeshBuilder.CreateCylinder(name, cupShape);
    this.rootMesh.bakeTransformIntoVertices(offsetMatrix);

    this._material = new BABYLON.PBRMetallicRoughnessMaterial(name + 'DefaultMaterial', this._workshop.scene);
    this._material.baseColor = this._defaultColor;
    this.rootMesh.material = this._material;
  }

  spawn(): Part {
    return new Part(this._workshop);
  }

  setInteractionState(
    motionController: BABYLON.WebXRAbstractMotionController,
    interactionState: InteractionState
  ): void {
    super.setInteractionState(motionController, interactionState);

    // TODO make this an animation
    this._material.baseColor =
      this._combinedInteractionState === InteractionState.Hovering ? this._hoverColor : this._defaultColor;
  }
}

export class SpawnerShelf extends InteractionTarget {
  public readonly rootMesh: BABYLON.Mesh;
  private readonly _material: BABYLON.PBRMetallicRoughnessMaterial;
  private readonly _defaultColor = BABYLON.Color3.Gray();
  private readonly _hoverColor = BABYLON.Color3.Red();

  public readonly spawners = new Array<Spawner>();

  constructor(workshop: Workshop) {
    super();

    // Create the shelf on which the spawners will sit
    const shelfShape = { width: 0.7, height: 0.02, depth: 0.3 };
    this.rootMesh = BABYLON.MeshBuilder.CreateBox('shelf', shelfShape, workshop.scene);
    const offsetMatrix = BABYLON.Matrix.Identity();
    offsetMatrix.setTranslation(new BABYLON.Vector3(0, shelfShape.height / -2, 0));
    this.rootMesh.bakeTransformIntoVertices(offsetMatrix);

    this._material = new BABYLON.PBRMetallicRoughnessMaterial(name + 'DefaultMaterial', workshop.scene);
    this._material.baseColor = this._defaultColor;
    this.rootMesh.material = this._material;

    // Create spawners and put on shelf
    const stickSpawner = new Spawner('stickSpawner', workshop);
    stickSpawner.rootMesh.parent = this.rootMesh;
    stickSpawner.rootMesh.position.x = -0.15;
    this.spawners.push(stickSpawner);

    const connectorSpawner = new Spawner('connectorSpawner', workshop);
    connectorSpawner.rootMesh.parent = this.rootMesh;
    connectorSpawner.rootMesh.position.x = 0.15;
    this.spawners.push(connectorSpawner);
  }

  setInteractionState(
    motionController: BABYLON.WebXRAbstractMotionController,
    interactionState: InteractionState
  ): void {
    super.setInteractionState(motionController, interactionState);

    // TODO make this an animation
    this._material.baseColor =
      this._combinedInteractionState === InteractionState.Hovering ? this._hoverColor : this._defaultColor;
  }
}
