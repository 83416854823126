import * as BABYLON from '@babylonjs/core/Legacy/legacy';
import Workshop from './workshop';
import { InteractionState } from './interactionTarget';
import { SpawnerShelf } from './spawner';
import { Part } from './part';
import { MotionControllerData } from './motionControllerData';

export class Project {
  private readonly _spawnersShelf: SpawnerShelf;
  private readonly _parts = new Array<Part>();

  private readonly _controllers = new Map<BABYLON.WebXRAbstractMotionController, MotionControllerData>();

  constructor(private readonly _workshop: Workshop) {
    this._spawnersShelf = new SpawnerShelf(this._workshop);
    this._spawnersShelf.rootMesh.rotate(BABYLON.Vector3.Up(), Math.PI / 3);
    this._spawnersShelf.rootMesh.setAbsolutePosition(new BABYLON.Vector3(0.5, 0.7, 0.15));

    // Add controller add/remove listeners
    this._workshop.xr.input.onControllerRemovedObservable.add(this._onControllerRemoved.bind(this));
    this._workshop.xr.input.onControllerAddedObservable.add(inputSource => {
      inputSource.onMotionControllerInitObservable.add(motionController => {
        motionController.onModelLoadedObservable.add(this._onControllerAdded.bind(this, motionController));
      });
    });

    // Initialize state for all currently connected controllers
    this._workshop.xr.input.controllers.forEach(inputSource => {
      if (inputSource.motionController?.rootMesh) {
        this._onControllerAdded(inputSource.motionController);
      }
    });

    this._workshop.scene.actionManager = new BABYLON.ActionManager(this._workshop.scene);
    this._workshop.scene.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(
        { trigger: BABYLON.ActionManager.OnEveryFrameTrigger },
        this._onFrameTick.bind(this)
      )
    );
  }

  _onControllerAdded(motionController: BABYLON.WebXRAbstractMotionController): void {
    const motionControllerData = new MotionControllerData(motionController);
    this._controllers.set(motionController, motionControllerData);
  }

  _onControllerRemoved(inputSource: BABYLON.WebXRInputSource): void {
    const motionControllerData = this._controllers.get(inputSource.motionController);
    if (motionControllerData) {
      this._controllers.delete(inputSource.motionController);
      motionControllerData.reset();
    }
  }

  _onFrameTick(): void {
    this._controllers.forEach((motionControllerData, motionController) => {
      let component = motionController.getComponentOfType('squeeze');

      // fallback
      if (!component) {
        component = motionController.getMainComponent();
      }

      // Update
      this._updateHoverStates(motionControllerData);

      if (motionControllerData.interactionState === InteractionState.Hovering && component.pressed) {
        // Interaction started
        motionControllerData.startInteraction();
      } else if (motionControllerData.hasActiveInteraction && !component.pressed) {
        const motionControllerMesh = motionControllerData.motionController.rootMesh;
        if (motionControllerMesh.intersectsMesh(this._spawnersShelf.rootMesh, true, true)) {
          // Interaction completed unsuccessfully
          motionControllerData.reset();
        } else {
          // Interaction completed successfully
          this._parts.push(motionControllerData.carriedPart);
          motionControllerData.completeInteraction();
        }
      }
    });
  }

  _updateHoverStates(motionControllerData: MotionControllerData): void {
    const motionControllerMesh = motionControllerData.motionController.rootMesh;

    if (motionControllerData.hasActiveInteraction) {
      if (motionControllerMesh.intersectsMesh(this._spawnersShelf.rootMesh, true, true)) {
        this._spawnersShelf.setInteractionState(motionControllerData.motionController, InteractionState.Hovering);
      } else {
        this._spawnersShelf.setInteractionState(motionControllerData.motionController, InteractionState.None);
      }
      return;
    } else {
      this._spawnersShelf.setInteractionState(motionControllerData.motionController, InteractionState.None);

      // Check for Part intersections
      for (let index = 0; index < this._parts.length; index++) {
        const part = this._parts[index];
        if (motionControllerMesh.intersectsMesh(part.rootMesh, true)) {
          motionControllerData.startHover(part);
          return;
        }
      }

      // Check for Spawner intersections
      for (let index = 0; index < this._spawnersShelf.spawners.length; index++) {
        const spawner = this._spawnersShelf.spawners[index];
        if (motionControllerMesh.intersectsMesh(spawner.rootMesh, true)) {
          motionControllerData.startHover(spawner);
          return;
        }
      }

      // No match found
      motionControllerData.reset();
    }
  }
}
