import * as BABYLON from '@babylonjs/core/Legacy/legacy';

export enum InteractionState {
  None = 0,
  Hovering = 1,
  Spawning = 2,
  Moving = 3
}

export class InteractionTarget {
  protected _combinedInteractionState = InteractionState.None;
  protected readonly _interactionStates = new Map<BABYLON.WebXRAbstractMotionController, InteractionState>();

  setInteractionState(
    motionController: BABYLON.WebXRAbstractMotionController,
    interactionState: InteractionState
  ): void {
    const previousState = this._interactionStates.get(motionController);
    if (previousState !== interactionState) {
      this._interactionStates.set(motionController, interactionState);

      this._combinedInteractionState = InteractionState.None;
      this._interactionStates.forEach(interactionState => {
        if (this._combinedInteractionState < interactionState) {
          this._combinedInteractionState = interactionState;
        }
      });
    }
  }
}
