import * as BABYLON from '@babylonjs/core/Legacy/legacy';
import { Part } from './part';
import { Spawner } from './spawner';
import { InteractionTarget, InteractionState } from './interactionTarget';

export class MotionControllerData {
  private _interactionState = InteractionState.None;

  private _interactionTarget: InteractionTarget;

  private _carriedPartOriginalPosition?: BABYLON.Vector3;
  private _carriedPartOriginalRotation?: BABYLON.Quaternion;
  private _carriedPart?: Part;

  constructor(public readonly motionController: BABYLON.WebXRAbstractMotionController) {}

  get interactionState(): InteractionState {
    return this._interactionState;
  }

  get hasActiveInteraction(): boolean {
    return this._interactionState === InteractionState.Moving || this._interactionState === InteractionState.Spawning;
  }

  get carriedPart(): Part | null {
    return this._carriedPart;
  }

  startHover(interactionTarget: InteractionTarget): void {
    if (this._interactionState === InteractionState.Spawning || this._interactionState === InteractionState.Moving) {
      throw new Error('Cannot enter hover state during a move or spawn operation');
    }

    if (this._interactionTarget && this._interactionTarget !== interactionTarget) {
      this._interactionTarget.setInteractionState(this.motionController, InteractionState.None);
    }

    this._interactionState = InteractionState.Hovering;
    this._interactionTarget = interactionTarget;
    this._interactionTarget.setInteractionState(this.motionController, this._interactionState);
  }

  startInteraction(): void {
    if (this._interactionState !== InteractionState.Hovering) {
      throw new Error('New interactions can only begin in the hover state');
    }

    if (this._interactionTarget instanceof Spawner) {
      this._interactionState = InteractionState.Spawning;
      this._interactionTarget.setInteractionState(this.motionController, InteractionState.None);
      this._carriedPart = (this._interactionTarget as Spawner).spawn();
      this._carriedPart.rootMesh.parent = this.motionController.rootMesh;
      this._carriedPart.rootMesh.position.z = -0.1;
      this._interactionTarget = this._carriedPart;
    } else if (this._interactionTarget instanceof Part) {
      this._interactionState = InteractionState.Moving;
      this._carriedPart = this._interactionTarget;
      this._carriedPart.rootMesh.setParent(this.motionController.rootMesh);
      this._carriedPartOriginalPosition = this._carriedPart.rootMesh.absolutePosition.clone();
      this._carriedPartOriginalRotation = this._carriedPart.rootMesh.absoluteRotationQuaternion.clone();
    } else {
      throw new Error('Cannot start an interaction without a spawner or part');
    }

    this._interactionTarget.setInteractionState(this.motionController, this._interactionState);
  }

  completeInteraction(): void {
    if (this._interactionState === InteractionState.None) {
      throw new Error('Cannot end an interaction when one is not in progress');
    }

    // Detatch and leave in new location
    this._carriedPart.rootMesh.setParent(null);

    this._interactionState = InteractionState.Hovering;
    this._interactionTarget.setInteractionState(this.motionController, this._interactionState);
    this._carriedPartOriginalPosition = null;
    this._carriedPartOriginalRotation = null;
    this._carriedPart = null;
  }

  reset(): void {
    // If an existing part, put it back where it came from.  If new, get rid of it
    if (this._interactionState === InteractionState.Moving) {
      this._carriedPart.rootMesh.setParent(null);
      this._carriedPart.rootMesh.position = this._carriedPartOriginalPosition;
      this._carriedPart.rootMesh.rotationQuaternion = this._carriedPartOriginalRotation;
    } else if (this._interactionState === InteractionState.Spawning) {
      this._carriedPart.dispose();
    }

    this._interactionState = InteractionState.None;

    if (this._interactionTarget) {
      this._interactionTarget.setInteractionState(this.motionController, InteractionState.None);
      this._interactionTarget = null;
    }

    this._carriedPartOriginalPosition = null;
    this._carriedPartOriginalRotation = null;
    this._carriedPart = null;
  }
}
