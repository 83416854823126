import * as BABYLON from '@babylonjs/core/Legacy/legacy';
import { InteractionTarget, InteractionState } from './interactionTarget';
import Workshop from './workshop';

export class Part extends InteractionTarget {
  public readonly rootMesh: BABYLON.AbstractMesh;

  private readonly _material: BABYLON.PBRMetallicRoughnessMaterial;
  private readonly _defaultColor = BABYLON.Color3.Green();
  private readonly _hoverColor = BABYLON.Color3.Red();
  private readonly _movingColor = BABYLON.Color3.Purple();

  constructor(workshop: Workshop) {
    super();

    const boxShape = { height: 0.1, width: 0.5, depth: 0.1 };
    this.rootMesh = BABYLON.MeshBuilder.CreateBox('part', boxShape);

    this._material = new BABYLON.PBRMetallicRoughnessMaterial(name + 'DefaultMaterial', workshop.scene);
    this._material.baseColor = this._defaultColor;
    this.rootMesh.material = this._material;
  }

  dispose(): void {
    this.rootMesh.dispose();
  }

  setInteractionState(
    motionController: BABYLON.WebXRAbstractMotionController,
    interactionState: InteractionState
  ): void {
    super.setInteractionState(motionController, interactionState);

    // TODO make this an animation
    switch (this._combinedInteractionState) {
      case InteractionState.Moving:
      case InteractionState.Spawning:
        this._material.baseColor = this._movingColor;
        break;

      case InteractionState.Hovering:
        this._material.baseColor = this._hoverColor;
        break;

      case InteractionState.None:
      default:
        this._material.baseColor = this._defaultColor;
        break;
    }
  }
}
