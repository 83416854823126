import { Effect } from "../Materials/effect";
import "./ShadersInclude/pbrFragmentDeclaration";
import "./ShadersInclude/pbrUboDeclaration";
import "./ShadersInclude/pbrFragmentExtraDeclaration";
import "./ShadersInclude/lightFragmentDeclaration";
import "./ShadersInclude/lightUboDeclaration";
import "./ShadersInclude/pbrFragmentSamplersDeclaration";
import "./ShadersInclude/imageProcessingDeclaration";
import "./ShadersInclude/clipPlaneFragmentDeclaration";
import "./ShadersInclude/logDepthDeclaration";
import "./ShadersInclude/fogFragmentDeclaration";
import "./ShadersInclude/helperFunctions";
import "./ShadersInclude/pbrHelperFunctions";
import "./ShadersInclude/imageProcessingFunctions";
import "./ShadersInclude/shadowsFragmentFunctions";
import "./ShadersInclude/harmonicsFunctions";
import "./ShadersInclude/pbrDirectLightingSetupFunctions";
import "./ShadersInclude/pbrDirectLightingFalloffFunctions";
import "./ShadersInclude/pbrBRDFFunctions";
import "./ShadersInclude/pbrDirectLightingFunctions";
import "./ShadersInclude/pbrIBLFunctions";
import "./ShadersInclude/bumpFragmentFunctions";
import "./ShadersInclude/reflectionFunction";
import "./ShadersInclude/clipPlaneFragment";
import "./ShadersInclude/bumpFragment";
import "./ShadersInclude/depthPrePass";
import "./ShadersInclude/lightFragment";
import "./ShadersInclude/logDepthFragment";
import "./ShadersInclude/fogFragment";
import "./ShadersInclude/pbrDebug";

let name = 'pbrPixelShader';
let shader = `#if defined(BUMP) || !defined(NORMAL) || defined(FORCENORMALFORWARD) || defined(SPECULARAA) || defined(CLEARCOAT_BUMP) || defined(ANISOTROPIC)
#extension GL_OES_standard_derivatives : enable
#endif
#ifdef LODBASEDMICROSFURACE
#extension GL_EXT_shader_texture_lod : enable
#endif
#define CUSTOM_FRAGMENT_BEGIN
#ifdef LOGARITHMICDEPTH
#extension GL_EXT_frag_depth : enable
#endif
precision highp float;

#ifndef FROMLINEARSPACE
#define FROMLINEARSPACE
#endif

#include<__decl__pbrFragment>
#include<pbrFragmentExtraDeclaration>
#include<__decl__lightFragment>[0..maxSimultaneousLights]
#include<pbrFragmentSamplersDeclaration>
#include<imageProcessingDeclaration>
#include<clipPlaneFragmentDeclaration>
#include<logDepthDeclaration>
#include<fogFragmentDeclaration>

#include<helperFunctions>
#include<pbrHelperFunctions>
#include<imageProcessingFunctions>
#include<shadowsFragmentFunctions>
#include<harmonicsFunctions>
#include<pbrDirectLightingSetupFunctions>
#include<pbrDirectLightingFalloffFunctions>
#include<pbrBRDFFunctions>
#include<pbrDirectLightingFunctions>
#include<pbrIBLFunctions>
#include<bumpFragmentFunctions>
#ifdef REFLECTION
#include<reflectionFunction>
#endif
#define CUSTOM_FRAGMENT_DEFINITIONS

void main(void) {
#define CUSTOM_FRAGMENT_MAIN_BEGIN
#include<clipPlaneFragment>

vec3 viewDirectionW=normalize(vEyePosition.xyz-vPositionW);
#ifdef NORMAL
vec3 normalW=normalize(vNormalW);
#else
vec3 normalW=normalize(cross(dFdx(vPositionW),dFdy(vPositionW)))*vEyePosition.w;
#endif
#ifdef CLEARCOAT

vec3 clearCoatNormalW=normalW;
#endif
#include<bumpFragment>
#if defined(FORCENORMALFORWARD) && defined(NORMAL)
vec3 faceNormal=normalize(cross(dFdx(vPositionW),dFdy(vPositionW)))*vEyePosition.w;
#if defined(TWOSIDEDLIGHTING)
faceNormal=gl_FrontFacing ? faceNormal : -faceNormal;
#endif
normalW*=sign(dot(normalW,faceNormal));
#endif
#if defined(TWOSIDEDLIGHTING) && defined(NORMAL)
normalW=gl_FrontFacing ? normalW : -normalW;
#endif


vec3 surfaceAlbedo=vAlbedoColor.rgb;

float alpha=vAlbedoColor.a;
#ifdef ALBEDO
vec4 albedoTexture=texture2D(albedoSampler,vAlbedoUV+uvOffset);
#if defined(ALPHAFROMALBEDO) || defined(ALPHATEST)
alpha*=albedoTexture.a;
#endif
surfaceAlbedo*=toLinearSpace(albedoTexture.rgb);
surfaceAlbedo*=vAlbedoInfos.y;
#endif
#ifdef VERTEXCOLOR
surfaceAlbedo*=vColor.rgb;
#endif
#define CUSTOM_FRAGMENT_UPDATE_ALBEDO

#ifdef OPACITY
vec4 opacityMap=texture2D(opacitySampler,vOpacityUV+uvOffset);
#ifdef OPACITYRGB
alpha=getLuminance(opacityMap.rgb);
#else
alpha*=opacityMap.a;
#endif
alpha*=vOpacityInfos.y;
#endif
#ifdef VERTEXALPHA
alpha*=vColor.a;
#endif
#if !defined(SS_LINKREFRACTIONTOTRANSPARENCY) && !defined(ALPHAFRESNEL)
#ifdef ALPHATEST
if (alpha<ALPHATESTVALUE)
discard;
#ifndef ALPHABLEND

alpha=1.0;
#endif
#endif
#endif
#define CUSTOM_FRAGMENT_UPDATE_ALPHA
#include<depthPrePass>
#define CUSTOM_FRAGMENT_BEFORE_LIGHTS

vec3 ambientOcclusionColor=vec3(1.,1.,1.);
#ifdef AMBIENT
vec3 ambientOcclusionColorMap=texture2D(ambientSampler,vAmbientUV+uvOffset).rgb*vAmbientInfos.y;
#ifdef AMBIENTINGRAYSCALE
ambientOcclusionColorMap=vec3(ambientOcclusionColorMap.r,ambientOcclusionColorMap.r,ambientOcclusionColorMap.r);
#endif
ambientOcclusionColor=mix(ambientOcclusionColor,ambientOcclusionColorMap,vAmbientInfos.z);
#endif
#ifdef UNLIT
vec3 diffuseBase=vec3(1.,1.,1.);
#else

float microSurface=vReflectivityColor.a;
vec3 surfaceReflectivityColor=vReflectivityColor.rgb;
#ifdef METALLICWORKFLOW
vec2 metallicRoughness=surfaceReflectivityColor.rg;
#ifdef REFLECTIVITY
vec4 surfaceMetallicColorMap=texture2D(reflectivitySampler,vReflectivityUV+uvOffset);
#ifdef AOSTOREINMETALMAPRED
vec3 aoStoreInMetalMap=vec3(surfaceMetallicColorMap.r,surfaceMetallicColorMap.r,surfaceMetallicColorMap.r);
ambientOcclusionColor=mix(ambientOcclusionColor,aoStoreInMetalMap,vReflectivityInfos.z);
#endif
#ifdef METALLNESSSTOREINMETALMAPBLUE
metallicRoughness.r*=surfaceMetallicColorMap.b;
#else
metallicRoughness.r*=surfaceMetallicColorMap.r;
#endif
#ifdef ROUGHNESSSTOREINMETALMAPALPHA
metallicRoughness.g*=surfaceMetallicColorMap.a;
#else
#ifdef ROUGHNESSSTOREINMETALMAPGREEN
metallicRoughness.g*=surfaceMetallicColorMap.g;
#endif
#endif
#endif
#ifdef MICROSURFACEMAP
vec4 microSurfaceTexel=texture2D(microSurfaceSampler,vMicroSurfaceSamplerUV+uvOffset)*vMicroSurfaceSamplerInfos.y;
metallicRoughness.g*=microSurfaceTexel.r;
#endif
#define CUSTOM_FRAGMENT_UPDATE_METALLICROUGHNESS

microSurface=1.0-metallicRoughness.g;

vec3 baseColor=surfaceAlbedo;
#ifdef REFLECTANCE





surfaceAlbedo=baseColor.rgb*(1.0-metallicRoughness.r);

surfaceReflectivityColor=mix(0.16*reflectance*reflectance,baseColor,metallicRoughness.r);
#else
vec3 metallicF0=vec3(vReflectivityColor.a,vReflectivityColor.a,vReflectivityColor.a);
#ifdef METALLICF0FACTORFROMMETALLICMAP
#ifdef REFLECTIVITY
metallicF0*=surfaceMetallicColorMap.a;
#endif
#endif

surfaceAlbedo=mix(baseColor.rgb*(1.0-metallicF0.r),vec3(0.,0.,0.),metallicRoughness.r);

surfaceReflectivityColor=mix(metallicF0,baseColor,metallicRoughness.r);
#endif
#else
#ifdef REFLECTIVITY
vec4 surfaceReflectivityColorMap=texture2D(reflectivitySampler,vReflectivityUV+uvOffset);
surfaceReflectivityColor*=toLinearSpace(surfaceReflectivityColorMap.rgb);
surfaceReflectivityColor*=vReflectivityInfos.y;
#ifdef MICROSURFACEFROMREFLECTIVITYMAP
microSurface*=surfaceReflectivityColorMap.a;
microSurface*=vReflectivityInfos.z;
#else
#ifdef MICROSURFACEAUTOMATIC
microSurface*=computeDefaultMicroSurface(microSurface,surfaceReflectivityColor);
#endif
#ifdef MICROSURFACEMAP
vec4 microSurfaceTexel=texture2D(microSurfaceSampler,vMicroSurfaceSamplerUV+uvOffset)*vMicroSurfaceSamplerInfos.y;
microSurface*=microSurfaceTexel.r;
#endif
#define CUSTOM_FRAGMENT_UPDATE_MICROSURFACE
#endif
#endif
#endif

microSurface=saturate(microSurface);

float roughness=1.-microSurface;

#ifdef ALPHAFRESNEL
#if defined(ALPHATEST) || defined(ALPHABLEND)



float opacityPerceptual=alpha;
#ifdef LINEARALPHAFRESNEL
float opacity0=opacityPerceptual;
#else
float opacity0=opacityPerceptual*opacityPerceptual;
#endif
float opacity90=fresnelGrazingReflectance(opacity0);
vec3 normalForward=faceforward(normalW,-viewDirectionW,normalW);

alpha=getReflectanceFromAnalyticalBRDFLookup_Jones(saturate(dot(viewDirectionW,normalForward)),vec3(opacity0),vec3(opacity90),sqrt(microSurface)).x;
#ifdef ALPHATEST
if (alpha<ALPHATESTVALUE)
discard;
#ifndef ALPHABLEND

alpha=1.0;
#endif
#endif
#endif
#endif

float NdotVUnclamped=dot(normalW,viewDirectionW);

float NdotV=absEps(NdotVUnclamped);
float alphaG=convertRoughnessToAverageSlope(roughness);
vec2 AARoughnessFactors=getAARoughnessFactors(normalW.xyz);
#ifdef SPECULARAA

alphaG+=AARoughnessFactors.y;
#endif
#ifdef ANISOTROPIC
float anisotropy=vAnisotropy.b;
vec3 anisotropyDirection=vec3(vAnisotropy.xy,0.);
#ifdef ANISOTROPIC_TEXTURE
vec3 anisotropyMapData=texture2D(anisotropySampler,vAnisotropyUV+uvOffset).rgb*vAnisotropyInfos.y;
anisotropy*=anisotropyMapData.b;
anisotropyDirection.rg*=anisotropyMapData.rg*2.0-1.0;
#endif
mat3 anisoTBN=mat3(normalize(TBN[0]),normalize(TBN[1]),normalize(TBN[2]));
vec3 anisotropicTangent=normalize(anisoTBN*anisotropyDirection);
vec3 anisotropicBitangent=normalize(cross(anisoTBN[2],anisotropicTangent));
vec3 anisotropicNormal=getAnisotropicBentNormals(anisotropicTangent,anisotropicBitangent,normalW,viewDirectionW,anisotropy);
#endif

#ifdef SS_REFRACTION
vec4 environmentRefraction=vec4(0.,0.,0.,0.);
#ifdef ANISOTROPIC
vec3 refractionVector=refract(-viewDirectionW,anisotropicNormal,vRefractionInfos.y);
#else
vec3 refractionVector=refract(-viewDirectionW,normalW,vRefractionInfos.y);
#endif
#ifdef SS_REFRACTIONMAP_OPPOSITEZ
refractionVector.z*=-1.0;
#endif

#ifdef SS_REFRACTIONMAP_3D
refractionVector.y=refractionVector.y*vRefractionInfos.w;
vec3 refractionCoords=refractionVector;
refractionCoords=vec3(refractionMatrix*vec4(refractionCoords,0));
#else
vec3 vRefractionUVW=vec3(refractionMatrix*(view*vec4(vPositionW+refractionVector*vRefractionInfos.z,1.0)));
vec2 refractionCoords=vRefractionUVW.xy/vRefractionUVW.z;
refractionCoords.y=1.0-refractionCoords.y;
#endif
#ifdef SS_LODINREFRACTIONALPHA
float refractionLOD=getLodFromAlphaG(vRefractionMicrosurfaceInfos.x,alphaG,NdotVUnclamped);
#elif defined(SS_LINEARSPECULARREFRACTION)
float refractionLOD=getLinearLodFromRoughness(vRefractionMicrosurfaceInfos.x,roughness);
#else
float refractionLOD=getLodFromAlphaG(vRefractionMicrosurfaceInfos.x,alphaG);
#endif
#ifdef LODBASEDMICROSFURACE

refractionLOD=refractionLOD*vRefractionMicrosurfaceInfos.y+vRefractionMicrosurfaceInfos.z;
#ifdef SS_LODINREFRACTIONALPHA









float automaticRefractionLOD=UNPACK_LOD(sampleRefraction(refractionSampler,refractionCoords).a);
float requestedRefractionLOD=max(automaticRefractionLOD,refractionLOD);
#else
float requestedRefractionLOD=refractionLOD;
#endif
environmentRefraction=sampleRefractionLod(refractionSampler,refractionCoords,requestedRefractionLOD);
#else
float lodRefractionNormalized=saturate(refractionLOD/log2(vRefractionMicrosurfaceInfos.x));
float lodRefractionNormalizedDoubled=lodRefractionNormalized*2.0;
vec4 environmentRefractionMid=sampleRefraction(refractionSampler,refractionCoords);
if(lodRefractionNormalizedDoubled<1.0){
environmentRefraction=mix(
sampleRefraction(refractionSamplerHigh,refractionCoords),
environmentRefractionMid,
lodRefractionNormalizedDoubled
);
}else{
environmentRefraction=mix(
environmentRefractionMid,
sampleRefraction(refractionSamplerLow,refractionCoords),
lodRefractionNormalizedDoubled-1.0
);
}
#endif
#ifdef SS_RGBDREFRACTION
environmentRefraction.rgb=fromRGBD(environmentRefraction);
#endif
#ifdef SS_GAMMAREFRACTION
environmentRefraction.rgb=toLinearSpace(environmentRefraction.rgb);
#endif

environmentRefraction.rgb*=vRefractionInfos.x;
#endif

#ifdef REFLECTION
vec4 environmentRadiance=vec4(0.,0.,0.,0.);
vec3 environmentIrradiance=vec3(0.,0.,0.);
#ifdef ANISOTROPIC
vec3 reflectionVector=computeReflectionCoords(vec4(vPositionW,1.0),anisotropicNormal);
#else
vec3 reflectionVector=computeReflectionCoords(vec4(vPositionW,1.0),normalW);
#endif
#ifdef REFLECTIONMAP_OPPOSITEZ
reflectionVector.z*=-1.0;
#endif

#ifdef REFLECTIONMAP_3D
vec3 reflectionCoords=reflectionVector;
#else
vec2 reflectionCoords=reflectionVector.xy;
#ifdef REFLECTIONMAP_PROJECTION
reflectionCoords/=reflectionVector.z;
#endif
reflectionCoords.y=1.0-reflectionCoords.y;
#endif
#if defined(LODINREFLECTIONALPHA) && !defined(REFLECTIONMAP_SKYBOX)
float reflectionLOD=getLodFromAlphaG(vReflectionMicrosurfaceInfos.x,alphaG,NdotVUnclamped);
#elif defined(LINEARSPECULARREFLECTION)
float refractionLOD=getLinearLodFromRoughness(vReflectionMicrosurfaceInfos.x,roughness);
#else
float reflectionLOD=getLodFromAlphaG(vReflectionMicrosurfaceInfos.x,alphaG);
#endif
#ifdef LODBASEDMICROSFURACE

reflectionLOD=reflectionLOD*vReflectionMicrosurfaceInfos.y+vReflectionMicrosurfaceInfos.z;
#ifdef LODINREFLECTIONALPHA









float automaticReflectionLOD=UNPACK_LOD(sampleReflection(reflectionSampler,reflectionCoords).a);
float requestedReflectionLOD=max(automaticReflectionLOD,reflectionLOD);
#else
float requestedReflectionLOD=reflectionLOD;
#endif
environmentRadiance=sampleReflectionLod(reflectionSampler,reflectionCoords,requestedReflectionLOD);
#else
float lodReflectionNormalized=saturate(reflectionLOD/log2(vReflectionMicrosurfaceInfos.x));
float lodReflectionNormalizedDoubled=lodReflectionNormalized*2.0;
vec4 environmentSpecularMid=sampleReflection(reflectionSampler,reflectionCoords);
if(lodReflectionNormalizedDoubled<1.0){
environmentRadiance=mix(
sampleReflection(reflectionSamplerHigh,reflectionCoords),
environmentSpecularMid,
lodReflectionNormalizedDoubled
);
}else{
environmentRadiance=mix(
environmentSpecularMid,
sampleReflection(reflectionSamplerLow,reflectionCoords),
lodReflectionNormalizedDoubled-1.0
);
}
#endif
#ifdef RGBDREFLECTION
environmentRadiance.rgb=fromRGBD(environmentRadiance);
#endif
#ifdef GAMMAREFLECTION
environmentRadiance.rgb=toLinearSpace(environmentRadiance.rgb);
#endif

#ifdef USESPHERICALFROMREFLECTIONMAP
#if defined(NORMAL) && defined(USESPHERICALINVERTEX)
environmentIrradiance=vEnvironmentIrradiance;
#else
#ifdef ANISOTROPIC
vec3 irradianceVector=vec3(reflectionMatrix*vec4(anisotropicNormal,0)).xyz;
#else
vec3 irradianceVector=vec3(reflectionMatrix*vec4(normalW,0)).xyz;
#endif
#ifdef REFLECTIONMAP_OPPOSITEZ
irradianceVector.z*=-1.0;
#endif
environmentIrradiance=computeEnvironmentIrradiance(irradianceVector);
#endif
#elif defined(USEIRRADIANCEMAP)
environmentIrradiance=sampleReflection(irradianceSampler,reflectionCoords).rgb;
#ifdef RGBDREFLECTION
environmentIrradiance.rgb=fromRGBD(environmentIrradiance);
#endif
#ifdef GAMMAREFLECTION
environmentIrradiance.rgb=toLinearSpace(environmentIrradiance.rgb);
#endif
#endif

environmentRadiance.rgb*=vReflectionInfos.x;
environmentRadiance.rgb*=vReflectionColor.rgb;
environmentIrradiance*=vReflectionColor.rgb;
#endif

float reflectance=max(max(surfaceReflectivityColor.r,surfaceReflectivityColor.g),surfaceReflectivityColor.b);
float reflectance90=fresnelGrazingReflectance(reflectance);
vec3 specularEnvironmentR0=surfaceReflectivityColor.rgb;
vec3 specularEnvironmentR90=vec3(1.0,1.0,1.0)*reflectance90;

#ifdef SHEEN
float sheenIntensity=vSheenColor.a;
#ifdef SHEEN_TEXTURE
vec4 sheenMapData=texture2D(sheenSampler,vSheenUV+uvOffset)*vSheenInfos.y;
sheenIntensity*=sheenMapData.a;
#endif
#ifdef SHEEN_LINKWITHALBEDO
float sheenFactor=pow5(1.0-sheenIntensity);
vec3 sheenColor=baseColor.rgb*(1.0-sheenFactor);
float sheenRoughness=sheenIntensity;

surfaceAlbedo.rgb*=sheenFactor;
#else
vec3 sheenColor=vSheenColor.rgb;
#ifdef SHEEN_TEXTURE
sheenColor.rgb*=toLinearSpace(sheenMapData.rgb);
#endif
float sheenRoughness=roughness;

sheenIntensity*=(1.-reflectance);

sheenColor*=sheenIntensity;
#endif

#if defined(REFLECTION)
float sheenAlphaG=convertRoughnessToAverageSlope(sheenRoughness);
#ifdef SPECULARAA

sheenAlphaG+=AARoughnessFactors.y;
#endif
vec4 environmentSheenRadiance=vec4(0.,0.,0.,0.);

#if defined(LODINREFLECTIONALPHA) && !defined(REFLECTIONMAP_SKYBOX)
float sheenReflectionLOD=getLodFromAlphaG(vReflectionMicrosurfaceInfos.x,sheenAlphaG,NdotVUnclamped);
#elif defined(LINEARSPECULARREFLECTION)
float sheenReflectionLOD=getLinearLodFromRoughness(vReflectionMicrosurfaceInfos.x,sheenRoughness);
#else
float sheenReflectionLOD=getLodFromAlphaG(vReflectionMicrosurfaceInfos.x,sheenAlphaG);
#endif
#ifdef LODBASEDMICROSFURACE

sheenReflectionLOD=sheenReflectionLOD*vReflectionMicrosurfaceInfos.y+vReflectionMicrosurfaceInfos.z;
environmentSheenRadiance=sampleReflectionLod(reflectionSampler,reflectionCoords,sheenReflectionLOD);
#else
float lodSheenReflectionNormalized=saturate(sheenReflectionLOD/log2(vReflectionMicrosurfaceInfos.x));
float lodSheenReflectionNormalizedDoubled=lodSheenReflectionNormalized*2.0;
vec4 environmentSheenMid=sampleReflection(reflectionSampler,reflectionCoords);
if(lodSheenReflectionNormalizedDoubled<1.0){
environmentSheenRadiance=mix(
sampleReflection(reflectionSamplerHigh,reflectionCoords),
environmentSheenMid,
lodSheenReflectionNormalizedDoubled
);
}else{
environmentSheenRadiance=mix(
environmentSheenMid,
sampleReflection(reflectionSamplerLow,reflectionCoords),
lodSheenReflectionNormalizedDoubled-1.0
);
}
#endif
#ifdef RGBDREFLECTION
environmentSheenRadiance.rgb=fromRGBD(environmentSheenRadiance);
#endif
#ifdef GAMMAREFLECTION
environmentSheenRadiance.rgb=toLinearSpace(environmentSheenRadiance.rgb);
#endif

environmentSheenRadiance.rgb*=vReflectionInfos.x;
environmentSheenRadiance.rgb*=vReflectionColor.rgb;
#endif
#endif

#ifdef CLEARCOAT

float clearCoatIntensity=vClearCoatParams.x;
float clearCoatRoughness=vClearCoatParams.y;
#ifdef CLEARCOAT_TEXTURE
vec2 clearCoatMapData=texture2D(clearCoatSampler,vClearCoatUV+uvOffset).rg*vClearCoatInfos.y;
clearCoatIntensity*=clearCoatMapData.x;
clearCoatRoughness*=clearCoatMapData.y;
#endif
#ifdef CLEARCOAT_TINT
vec3 clearCoatColor=vClearCoatTintParams.rgb;
float clearCoatThickness=vClearCoatTintParams.a;
#ifdef CLEARCOAT_TINT_TEXTURE
vec4 clearCoatTintMapData=texture2D(clearCoatTintSampler,vClearCoatTintUV+uvOffset);
clearCoatColor*=toLinearSpace(clearCoatTintMapData.rgb);
clearCoatThickness*=clearCoatTintMapData.a;
#endif
clearCoatColor=computeColorAtDistanceInMedia(clearCoatColor,clearCoatColorAtDistance);
#endif




vec3 specularEnvironmentR0Updated=getR0RemappedForClearCoat(specularEnvironmentR0);
specularEnvironmentR0=mix(specularEnvironmentR0,specularEnvironmentR0Updated,clearCoatIntensity);
#ifdef CLEARCOAT_BUMP
#ifdef NORMALXYSCALE
float clearCoatNormalScale=1.0;
#else
float clearCoatNormalScale=vClearCoatBumpInfos.y;
#endif
#if defined(TANGENT) && defined(NORMAL)
mat3 TBNClearCoat=vTBN;
#else
mat3 TBNClearCoat=cotangent_frame(clearCoatNormalW*clearCoatNormalScale,vPositionW,vClearCoatBumpUV,vClearCoatTangentSpaceParams);
#endif
#ifdef OBJECTSPACE_NORMALMAP
clearCoatNormalW=normalize(texture2D(clearCoatBumpSampler,vClearCoatBumpUV+uvOffset).xyz*2.0-1.0);
clearCoatNormalW=normalize(mat3(normalMatrix)*clearCoatNormalW);
#else
clearCoatNormalW=perturbNormal(TBNClearCoat,texture2D(clearCoatBumpSampler,vClearCoatBumpUV+uvOffset).xyz,vClearCoatBumpInfos.y);
#endif
#endif
#if defined(FORCENORMALFORWARD) && defined(NORMAL)
clearCoatNormalW*=sign(dot(clearCoatNormalW,faceNormal));
#endif
#if defined(TWOSIDEDLIGHTING) && defined(NORMAL)
clearCoatNormalW=gl_FrontFacing ? clearCoatNormalW : -clearCoatNormalW;
#endif

vec2 clearCoatAARoughnessFactors=getAARoughnessFactors(clearCoatNormalW.xyz);

float clearCoatNdotVUnclamped=dot(clearCoatNormalW,viewDirectionW);

float clearCoatNdotV=absEps(clearCoatNdotVUnclamped);

#if defined(REFLECTION)
float clearCoatAlphaG=convertRoughnessToAverageSlope(clearCoatRoughness);
#ifdef SPECULARAA

clearCoatAlphaG+=clearCoatAARoughnessFactors.y;
#endif
vec4 environmentClearCoatRadiance=vec4(0.,0.,0.,0.);
vec3 clearCoatReflectionVector=computeReflectionCoords(vec4(vPositionW,1.0),clearCoatNormalW);
#ifdef REFLECTIONMAP_OPPOSITEZ
clearCoatReflectionVector.z*=-1.0;
#endif

#ifdef REFLECTIONMAP_3D
vec3 clearCoatReflectionCoords=clearCoatReflectionVector;
#else
vec2 clearCoatReflectionCoords=clearCoatReflectionVector.xy;
#ifdef REFLECTIONMAP_PROJECTION
clearCoatReflectionCoords/=clearCoatReflectionVector.z;
#endif
clearCoatReflectionCoords.y=1.0-clearCoatReflectionCoords.y;
#endif
#if defined(LODINREFLECTIONALPHA) && !defined(REFLECTIONMAP_SKYBOX)
float clearCoatReflectionLOD=getLodFromAlphaG(vReflectionMicrosurfaceInfos.x,clearCoatAlphaG,clearCoatNdotVUnclamped);
#elif defined(LINEARSPECULARREFLECTION)
float sheenReflectionLOD=getLinearLodFromRoughness(vReflectionMicrosurfaceInfos.x,clearCoatRoughness);
#else
float clearCoatReflectionLOD=getLodFromAlphaG(vReflectionMicrosurfaceInfos.x,clearCoatAlphaG);
#endif
#ifdef LODBASEDMICROSFURACE

clearCoatReflectionLOD=clearCoatReflectionLOD*vReflectionMicrosurfaceInfos.y+vReflectionMicrosurfaceInfos.z;
float requestedClearCoatReflectionLOD=clearCoatReflectionLOD;
environmentClearCoatRadiance=sampleReflectionLod(reflectionSampler,clearCoatReflectionCoords,requestedClearCoatReflectionLOD);
#else
float lodClearCoatReflectionNormalized=saturate(clearCoatReflectionLOD/log2(vReflectionMicrosurfaceInfos.x));
float lodClearCoatReflectionNormalizedDoubled=lodClearCoatReflectionNormalized*2.0;
vec4 environmentClearCoatMid=sampleReflection(reflectionSampler,reflectionCoords);
if(lodClearCoatReflectionNormalizedDoubled<1.0){
environmentClearCoatRadiance=mix(
sampleReflection(reflectionSamplerHigh,clearCoatReflectionCoords),
environmentClearCoatMid,
lodClearCoatReflectionNormalizedDoubled
);
}else{
environmentClearCoatRadiance=mix(
environmentClearCoatMid,
sampleReflection(reflectionSamplerLow,clearCoatReflectionCoords),
lodClearCoatReflectionNormalizedDoubled-1.0
);
}
#endif
#ifdef RGBDREFLECTION
environmentClearCoatRadiance.rgb=fromRGBD(environmentClearCoatRadiance);
#endif
#ifdef GAMMAREFLECTION
environmentClearCoatRadiance.rgb=toLinearSpace(environmentClearCoatRadiance.rgb);
#endif
#ifdef CLEARCOAT_TINT

vec3 clearCoatVRefract=-refract(vPositionW,clearCoatNormalW,vClearCoatRefractionParams.y);

float clearCoatNdotVRefract=absEps(dot(clearCoatNormalW,clearCoatVRefract));
vec3 absorption=vec3(0.);
#endif

environmentClearCoatRadiance.rgb*=vReflectionInfos.x;
environmentClearCoatRadiance.rgb*=vReflectionColor.rgb;
#endif
#endif

#if defined(ENVIRONMENTBRDF)

vec3 environmentBrdf=getBRDFLookup(NdotV,roughness);
#ifdef MS_BRDF_ENERGY_CONSERVATION
vec3 energyConservationFactor=getEnergyConservationFactor(specularEnvironmentR0,environmentBrdf);
#endif
#endif

#ifdef SUBSURFACE
#ifdef SS_REFRACTION
float refractionIntensity=vSubSurfaceIntensity.x;
#ifdef SS_LINKREFRACTIONTOTRANSPARENCY
refractionIntensity*=(1.0-alpha);

alpha=1.0;
#endif
#endif
#ifdef SS_TRANSLUCENCY
float translucencyIntensity=vSubSurfaceIntensity.y;
#endif
#ifdef SS_SCATTERING
float scatteringIntensity=vSubSurfaceIntensity.z;
#endif
#ifdef SS_THICKNESSANDMASK_TEXTURE
vec4 thicknessMap=texture2D(thicknessSampler,vThicknessUV+uvOffset);
float thickness=thicknessMap.r*vThicknessParam.y+vThicknessParam.x;
#ifdef SS_MASK_FROM_THICKNESS_TEXTURE
#ifdef SS_REFRACTION
refractionIntensity*=thicknessMap.g;
#endif
#ifdef SS_TRANSLUCENCY
translucencyIntensity*=thicknessMap.b;
#endif
#ifdef SS_SCATTERING
scatteringIntensity*=thicknessMap.a;
#endif
#endif
#else
float thickness=vThicknessParam.y;
#endif
#ifdef SS_TRANSLUCENCY
thickness=maxEps(thickness);
vec3 transmittance=transmittanceBRDF_Burley(vTintColor.rgb,vDiffusionDistance,thickness);
transmittance*=translucencyIntensity;
#endif
#endif


vec3 diffuseBase=vec3(0.,0.,0.);
#ifdef SPECULARTERM
vec3 specularBase=vec3(0.,0.,0.);
#endif
#ifdef CLEARCOAT
vec3 clearCoatBase=vec3(0.,0.,0.);
#endif
#ifdef SHEEN
vec3 sheenBase=vec3(0.,0.,0.);
#endif
#ifdef LIGHTMAP
vec4 lightmapColor=texture2D(lightmapSampler,vLightmapUV+uvOffset);
#ifdef RGBDLIGHTMAP
lightmapColor.rgb=fromRGBD(lightmapColor);
#endif
#ifdef GAMMALIGHTMAP
lightmapColor.rgb=toLinearSpace(lightmapColor.rgb);
#endif
lightmapColor.rgb*=vLightmapInfos.y;
#endif

preLightingInfo preInfo;
lightingInfo info;
float shadow=1.;
#include<lightFragment>[0..maxSimultaneousLights]

#if defined(ENVIRONMENTBRDF) && !defined(REFLECTIONMAP_SKYBOX)
vec3 specularEnvironmentReflectance=getReflectanceFromBRDFLookup(specularEnvironmentR0,environmentBrdf);
#ifdef RADIANCEOCCLUSION
#ifdef AMBIENTINGRAYSCALE
float ambientMonochrome=ambientOcclusionColor.r;
#else
float ambientMonochrome=getLuminance(ambientOcclusionColor);
#endif
float seo=environmentRadianceOcclusion(ambientMonochrome,NdotVUnclamped);
specularEnvironmentReflectance*=seo;
#endif
#ifdef HORIZONOCCLUSION
#ifdef BUMP
#ifdef REFLECTIONMAP_3D
float eho=environmentHorizonOcclusion(-viewDirectionW,normalW);
specularEnvironmentReflectance*=eho;
#endif
#endif
#endif
#else

vec3 specularEnvironmentReflectance=getReflectanceFromAnalyticalBRDFLookup_Jones(NdotV,specularEnvironmentR0,specularEnvironmentR90,sqrt(microSurface));
#endif

#if defined(SHEEN) && defined(REFLECTION)
vec3 sheenEnvironmentReflectance=getSheenReflectanceFromBRDFLookup(sheenColor,environmentBrdf);
#ifdef RADIANCEOCCLUSION
sheenEnvironmentReflectance*=seo;
#endif
#ifdef HORIZONOCCLUSION
#ifdef BUMP
#ifdef REFLECTIONMAP_3D
sheenEnvironmentReflectance*=eho;
#endif
#endif
#endif
#endif

#ifdef CLEARCOAT
#if defined(ENVIRONMENTBRDF) && !defined(REFLECTIONMAP_SKYBOX)

vec3 environmentClearCoatBrdf=getBRDFLookup(clearCoatNdotV,clearCoatRoughness);
vec3 clearCoatEnvironmentReflectance=getReflectanceFromBRDFLookup(vec3(vClearCoatRefractionParams.x),environmentClearCoatBrdf);
#ifdef RADIANCEOCCLUSION
float clearCoatSeo=environmentRadianceOcclusion(ambientMonochrome,clearCoatNdotVUnclamped);
clearCoatEnvironmentReflectance*=clearCoatSeo;
#endif
#ifdef HORIZONOCCLUSION
#ifdef BUMP
#ifdef REFLECTIONMAP_3D
float clearCoatEho=environmentHorizonOcclusion(-viewDirectionW,clearCoatNormalW);
clearCoatEnvironmentReflectance*=clearCoatEho;
#endif
#endif
#endif
#else

vec3 clearCoatEnvironmentReflectance=getReflectanceFromAnalyticalBRDFLookup_Jones(clearCoatNdotV,vec3(1.),vec3(1.),sqrt(1.-clearCoatRoughness));
#endif
clearCoatEnvironmentReflectance*=clearCoatIntensity;
#if defined(REFLECTION) && defined(CLEARCOAT_TINT)

absorption=computeClearCoatAbsorption(clearCoatNdotVRefract,clearCoatNdotVRefract,clearCoatColor,clearCoatThickness,clearCoatIntensity);
#ifdef REFLECTION
environmentIrradiance*=absorption;
#endif
#ifdef SHEEN
sheenEnvironmentReflectance*=absorption;
#endif
specularEnvironmentReflectance*=absorption;
#endif

float fresnelIBLClearCoat=fresnelSchlickGGX(clearCoatNdotV,vClearCoatRefractionParams.x,CLEARCOATREFLECTANCE90);
fresnelIBLClearCoat*=clearCoatIntensity;
float conservationFactor=(1.-fresnelIBLClearCoat);
#ifdef REFLECTION
environmentIrradiance*=conservationFactor;
#endif
#ifdef SHEEN
sheenEnvironmentReflectance*=conservationFactor;
#endif
specularEnvironmentReflectance*=conservationFactor;
#endif

#ifdef SS_REFRACTION
vec3 refractionTransmittance=vec3(refractionIntensity);
#ifdef SS_THICKNESSANDMASK_TEXTURE
vec3 volumeAlbedo=computeColorAtDistanceInMedia(vTintColor.rgb,vTintColor.w);





refractionTransmittance*=cocaLambert(volumeAlbedo,thickness);
#elif defined(SS_LINKREFRACTIONTOTRANSPARENCY)

float maxChannel=max(max(surfaceAlbedo.r,surfaceAlbedo.g),surfaceAlbedo.b);
vec3 volumeAlbedo=saturate(maxChannel*surfaceAlbedo);

environmentRefraction.rgb*=volumeAlbedo;
#else

vec3 volumeAlbedo=computeColorAtDistanceInMedia(vTintColor.rgb,vTintColor.w);
refractionTransmittance*=cocaLambert(volumeAlbedo,vThicknessParam.y);
#endif

surfaceAlbedo*=(1.-refractionIntensity);
#ifdef REFLECTION

environmentIrradiance*=(1.-refractionIntensity);
#endif

vec3 bounceSpecularEnvironmentReflectance=(2.0*specularEnvironmentReflectance)/(1.0+specularEnvironmentReflectance);
specularEnvironmentReflectance=mix(bounceSpecularEnvironmentReflectance,specularEnvironmentReflectance,refractionIntensity);

refractionTransmittance*=1.0-specularEnvironmentReflectance;
#endif

#if defined(REFLECTION) && defined(SS_TRANSLUCENCY)
#if defined(USESPHERICALINVERTEX)
vec3 irradianceVector=vec3(reflectionMatrix*vec4(normalW,0)).xyz;
#ifdef REFLECTIONMAP_OPPOSITEZ
irradianceVector.z*=-1.0;
#endif
#endif
#if defined(USESPHERICALFROMREFLECTIONMAP)
vec3 refractionIrradiance=computeEnvironmentIrradiance(-irradianceVector);
#elif defined(USEIRRADIANCEMAP)
vec3 refractionIrradiance=sampleReflection(irradianceSampler,-irradianceVector).rgb;
#ifdef RGBDREFLECTION
refractionIrradiance.rgb=fromRGBD(refractionIrradiance);
#endif
#ifdef GAMMAREFLECTION
refractionIrradiance.rgb=toLinearSpace(refractionIrradiance.rgb);
#endif
#else
vec3 refractionIrradiance=vec3(0.);
#endif
refractionIrradiance*=transmittance;
#endif



#ifndef METALLICWORKFLOW
#ifdef SPECULAR_GLOSSINESS_ENERGY_CONSERVATION
surfaceAlbedo.rgb=(1.-reflectance)*surfaceAlbedo.rgb;
#endif
#endif

#ifdef REFLECTION
vec3 finalIrradiance=environmentIrradiance;
#if defined(SS_TRANSLUCENCY)
finalIrradiance+=refractionIrradiance;
#endif
finalIrradiance*=surfaceAlbedo.rgb;
#endif

#ifdef SPECULARTERM
vec3 finalSpecular=specularBase;
finalSpecular=max(finalSpecular,0.0);

vec3 finalSpecularScaled=finalSpecular*vLightingIntensity.x*vLightingIntensity.w;
#if defined(ENVIRONMENTBRDF) && defined(MS_BRDF_ENERGY_CONSERVATION)
finalSpecularScaled*=energyConservationFactor;
#endif
#endif

#ifdef REFLECTION
vec3 finalRadiance=environmentRadiance.rgb;
finalRadiance*=specularEnvironmentReflectance;

vec3 finalRadianceScaled=finalRadiance*vLightingIntensity.z;
#if defined(ENVIRONMENTBRDF) && defined(MS_BRDF_ENERGY_CONSERVATION)
finalRadianceScaled*=energyConservationFactor;
#endif
#endif

#ifdef SS_REFRACTION
vec3 finalRefraction=environmentRefraction.rgb;
finalRefraction*=refractionTransmittance;
#endif

#ifdef CLEARCOAT
vec3 finalClearCoat=clearCoatBase;
finalClearCoat=max(finalClearCoat,0.0);

vec3 finalClearCoatScaled=finalClearCoat*vLightingIntensity.x*vLightingIntensity.w;
#if defined(ENVIRONMENTBRDF) && defined(MS_BRDF_ENERGY_CONSERVATION)
finalClearCoatScaled*=energyConservationFactor;
#endif

#ifdef REFLECTION
vec3 finalClearCoatRadiance=environmentClearCoatRadiance.rgb;
finalClearCoatRadiance*=clearCoatEnvironmentReflectance;

vec3 finalClearCoatRadianceScaled=finalClearCoatRadiance*vLightingIntensity.z;
#endif
#ifdef SS_REFRACTION
finalRefraction*=conservationFactor;
#ifdef CLEARCOAT_TINT
finalRefraction*=absorption;
#endif
#endif
#endif

#ifdef SHEEN
vec3 finalSheen=sheenBase*sheenColor;
finalSheen=max(finalSheen,0.0);
vec3 finalSheenScaled=finalSheen*vLightingIntensity.x*vLightingIntensity.w;





#ifdef REFLECTION
vec3 finalSheenRadiance=environmentSheenRadiance.rgb;
finalSheenRadiance*=sheenEnvironmentReflectance;

vec3 finalSheenRadianceScaled=finalSheenRadiance*vLightingIntensity.z;
#endif
#endif

#ifdef ALPHABLEND
float luminanceOverAlpha=0.0;
#if defined(REFLECTION) && defined(RADIANCEOVERALPHA)
luminanceOverAlpha+=getLuminance(finalRadianceScaled);
#if defined(CLEARCOAT)
luminanceOverAlpha+=getLuminance(finalClearCoatRadianceScaled);
#endif
#endif
#if defined(SPECULARTERM) && defined(SPECULAROVERALPHA)
luminanceOverAlpha+=getLuminance(finalSpecularScaled);
#endif
#if defined(CLEARCOAT) && defined(CLEARCOATOVERALPHA)
luminanceOverAlpha+=getLuminance(finalClearCoatScaled);
#endif
#if defined(RADIANCEOVERALPHA) || defined(SPECULAROVERALPHA)
alpha=saturate(alpha+luminanceOverAlpha*luminanceOverAlpha);
#endif
#endif
#endif


vec3 finalDiffuse=diffuseBase;
finalDiffuse*=surfaceAlbedo.rgb;
finalDiffuse=max(finalDiffuse,0.0);

vec3 finalAmbient=vAmbientColor;
finalAmbient*=surfaceAlbedo.rgb;

vec3 finalEmissive=vEmissiveColor;
#ifdef EMISSIVE
vec3 emissiveColorTex=texture2D(emissiveSampler,vEmissiveUV+uvOffset).rgb;
finalEmissive*=toLinearSpace(emissiveColorTex.rgb);
finalEmissive*=vEmissiveInfos.y;
#endif

#ifdef AMBIENT
vec3 ambientOcclusionForDirectDiffuse=mix(vec3(1.),ambientOcclusionColor,vAmbientInfos.w);
#else
vec3 ambientOcclusionForDirectDiffuse=ambientOcclusionColor;
#endif



vec4 finalColor=vec4(
finalAmbient*ambientOcclusionColor +
finalDiffuse*ambientOcclusionForDirectDiffuse*vLightingIntensity.x +
#ifndef UNLIT
#ifdef REFLECTION
finalIrradiance*ambientOcclusionColor*vLightingIntensity.z +
#endif
#ifdef SPECULARTERM


finalSpecularScaled +
#endif
#ifdef CLEARCOAT


finalClearCoatScaled +
#endif
#ifdef SHEEN


finalSheenScaled +
#endif
#ifdef REFLECTION


finalRadianceScaled +
#ifdef CLEARCOAT


finalClearCoatRadianceScaled +
#endif
#ifdef SHEEN


finalSheenRadianceScaled +
#endif
#endif
#ifdef SS_REFRACTION
finalRefraction*vLightingIntensity.z +
#endif
#endif
finalEmissive*vLightingIntensity.y,
alpha);

#ifdef LIGHTMAP
#ifndef LIGHTMAPEXCLUDED
#ifdef USELIGHTMAPASSHADOWMAP
finalColor.rgb*=lightmapColor.rgb;
#else
finalColor.rgb+=lightmapColor.rgb;
#endif
#endif
#endif
#define CUSTOM_FRAGMENT_BEFORE_FOG

finalColor=max(finalColor,0.0);
#include<logDepthFragment>
#include<fogFragment>(color,finalColor)
#ifdef IMAGEPROCESSINGPOSTPROCESS


finalColor.rgb=clamp(finalColor.rgb,0.,30.0);
#else

finalColor=applyImageProcessing(finalColor);
#endif
finalColor.a*=visibility;
#ifdef PREMULTIPLYALPHA

finalColor.rgb*=finalColor.a;
#endif
#define CUSTOM_FRAGMENT_BEFORE_FRAGCOLOR
gl_FragColor=finalColor;
#include<pbrDebug>
}
`;

Effect.ShadersStore[name] = shader;
/** @hidden */
export var pbrPixelShader = { name, shader };
