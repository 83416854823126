import * as BABYLON from '@babylonjs/core/Legacy/legacy';
import '@babylonjs/core/Meshes/meshBuilder';
import '@babylonjs/loaders/glTF';
import { Project } from './project';

export default class Workshop {
  private readonly _canvas: HTMLCanvasElement;
  private readonly _engine: BABYLON.Engine;
  private readonly _scene: BABYLON.Scene;
  private readonly _camera: BABYLON.UniversalCamera;
  private readonly _environment: BABYLON.EnvironmentHelper;
  private _xr: BABYLON.WebXRDefaultExperience;
  private _project: Project;

  constructor() {
    // get the canvas DOM element
    this._canvas = document.getElementById('renderCanvas') as HTMLCanvasElement;

    // load the 3D engine
    this._engine = new BABYLON.Engine(this._canvas, true);

    // create a basic BJS Scene object
    this._scene = new BABYLON.Scene(this._engine);

    // create a FreeCamera, and set its position to (x:0, y:5, z:-10)
    this._camera = new BABYLON.UniversalCamera('camera1', new BABYLON.Vector3(0, 1.6, 0), this._scene);
    this._camera.minZ = 0.01;
    this._camera.setTarget(new BABYLON.Vector3(0, 0.5, 0.5));

    // attach the camera to the canvas
    this._camera.attachControl(this._canvas, true);

    // create a basic light, aiming 0,1,0 - meaning, to the sky
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const light = new BABYLON.HemisphericLight('light1', new BABYLON.Vector3(0, 1, 0), this._scene);

    this._environment = this._scene.createDefaultEnvironment();

    // physics
    this._scene.enablePhysics(undefined, new BABYLON.AmmoJSPlugin());

    window.addEventListener('resize', this._onResize.bind(this));
    this._engine.runRenderLoop(this._renderCallback.bind(this));
  }

  get scene(): BABYLON.Scene {
    return this._scene;
  }

  get environment(): BABYLON.EnvironmentHelper {
    return this._environment;
  }

  get xr(): BABYLON.WebXRDefaultExperience {
    return this._xr;
  }

  async initialize(): Promise<void> {
    this._xr = await this._scene.createDefaultXRExperienceAsync({ floorMeshes: [this._environment.ground] });
    this._xr.baseExperience.featuresManager.disableFeature(BABYLON.WebXRFeatureName.TELEPORTATION);

    // await this._scene.debugLayer.show();
  }

  loadProject(): void {
    // TODO Save and delete the existng project

    this._project = new Project(this);
  }

  _renderCallback(): void {
    this._scene.render();
  }

  _onResize(): void {
    this._engine.resize();
  }
}
